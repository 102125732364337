import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useTranslation } from 'translations/hook';

import { UploadSignedSubscriptionDocument } from 'business/fund-manager/onboarding-subscription/services/types';
import { uploadSignedSubscriptionDocumentSchema } from 'business/fund-manager/onboarding-subscription/services/validation';
import FundManagerRoutes from 'business/fund-manager/router/routes';
import { VariantTypeEnum } from 'business/providers/notifications/types';
import { UploadedFile } from 'business/shared/components/uploaded-file/uploaded-file';
import { useUploadSignedSubscriptionDocumentMutation } from 'generated/graphql';
import { useFileUploadDeprecated } from 'technical/file-management/use-file-upload-deprecated';
import { ErrorLabel } from 'ui/error-label';
import { FileInput } from 'ui/form';
import { FormModalContainer } from 'ui/form-modal-container';

interface UploadSignedDocumentFormProps {
  onboardingSubscriptionId: string;
  onClose: () => void;
}
export const UploadSignedDocumentForm = ({
  onboardingSubscriptionId,
  onClose,
}: UploadSignedDocumentFormProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const methods = useForm<UploadSignedSubscriptionDocument>({
    defaultValues: {
      onboardingSubscriptionId,
    },
    resolver: yupResolver<UploadSignedSubscriptionDocument>(
      uploadSignedSubscriptionDocumentSchema,
    ),
  });

  const { handleFileUpload, removeUploadedFile, fileErrors, uploadedFileList } =
    useFileUploadDeprecated({
      onFileRemove: () => methods.setValue('subscriptionDocument.filePath', ''),
      onFileUpload: (file) => {
        methods.setValue('subscriptionDocument.filePath', file.filePath);
        methods.setValue('subscriptionDocument.fileName', file.name);
      },
    });

  const [uploadSignedSubscriptionDocument, { error }] =
    useUploadSignedSubscriptionDocumentMutation({
      onCompleted: () => {
        enqueueSnackbar(
          t(
            'pages.onboardingSubscription.readyForSignature.uploadSignedDocument.upload.success',
          ),
          {
            variant: VariantTypeEnum.SUCCESS,
          },
        );
        navigate(FundManagerRoutes.OnboardingSubscriptionHistory);
      },
      onError: () => {
        enqueueSnackbar(
          t(
            'pages.onboardingSubscription.readyForSignature.uploadSignedDocument.upload.error',
          ),
          {
            variant: VariantTypeEnum.ERROR,
          },
        );
      },
    });

  const onSubmit = (data: UploadSignedSubscriptionDocument) =>
    uploadSignedSubscriptionDocument({
      variables: {
        input: data,
      },
    }).catch(() => undefined);

  return (
    <FormModalContainer
      onSubmit={methods.handleSubmit(onSubmit)}
      isSubmitting={methods.formState.isSubmitting}
      onClose={onClose}
      title={t(
        'pages.onboardingSubscription.readyForSignature.uploadSignedDocument.title',
      )}
    >
      <Stack justifyContent="center" spacing={4} padding={2}>
        {error ? <ErrorLabel label={error.message} /> : null}

        <FileInput
          onChange={handleFileUpload}
          fileExtensionAcceptList={['.docx', '.pdf']}
          label={t(
            'pages.onboardingSubscription.readyForSignature.uploadSignedDocument.upload.label',
          )}
          disabled={uploadedFileList.length > 0}
        />

        {uploadedFileList.map((file) => (
          <UploadedFile
            file={file}
            handleRemoveFile={removeUploadedFile}
            key={file.filePath}
          />
        ))}
        {fileErrors ? <ErrorLabel label={fileErrors} /> : null}
      </Stack>
    </FormModalContainer>
  );
};
