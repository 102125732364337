import { ChangeEvent, useState } from 'react';

import { useGetUploadUrlLazyQuery } from 'generated/graphql';
import { generateUniqueFilePath } from 'technical/string/generate-unique-file-path';

import { uploadObjectAsync } from '.';
import { MAX_ATTACHMENTS_FILE_SIZE_MB, mbToByte } from './constant';
import { IFile } from './types';

interface Props {
  onFileUpload: (file: IFile) => void;
  onFileRemove: () => void;
  maximumFileSizeInMb?: number;
}

export const useFileUploadDeprecated = ({
  onFileUpload,
  onFileRemove,
  maximumFileSizeInMb = MAX_ATTACHMENTS_FILE_SIZE_MB,
}: Props) => {
  const [fileErrors, setFileErrors] = useState<string | null>(null);
  const [uploadedFileList, setUploadedFileList] = useState<IFile[]>([]);
  const [getUrl] = useGetUploadUrlLazyQuery();
  const totalUploadedSize = uploadedFileList.reduce(
    (acc, curr) => (acc = acc + curr.size),
    0,
  );

  const handleFileUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    setFileErrors(null);
    // the files are received as a FileList
    const files = e.currentTarget.files;

    if (!files) {
      return;
    }
    // We can convert to an array of File (File[])
    const fileArray = Array.from(files);
    const totalFilesSize = fileArray.reduce(
      (acc, curr) => (acc = acc + curr.size),
      0,
    );
    // if the size of all the files are supperior to the MAX_ATTACHMENTS_FILE_SIZE
    // we throw an error
    if (totalFilesSize + totalUploadedSize > mbToByte(maximumFileSizeInMb)) {
      setFileErrors('errors.exeeding_file_size');
      return;
    }

    // For each file, we create a new file and create a signed URL
    // using this file url we then upload the content and add it
    // to the uploaded file list
    return Promise.all(
      fileArray.map((file) => {
        const newFile: IFile = {
          name: file.name,
          type: file.type,
          size: file.size,
          filePath: generateUniqueFilePath(files[0].name),
        };

        return getUrl({
          variables: {
            input: {
              fileName: newFile.filePath,
            },
          },
        })
          .then(({ data }) => {
            if (data && data.uploadUrlResponse.url) {
              return uploadObjectAsync(file, data.uploadUrlResponse.url);
            }
          })
          .then((response) => {
            if (response && response.status === 200) {
              setUploadedFileList([...uploadedFileList, newFile]);
              onFileUpload(newFile);
              return;
            }

            throw Error('errors.upload-failed');
          })
          .catch(() => {
            setFileErrors('errors.upload-failed');
          });
      }),
    );
  };

  const removeUploadedFile = (file: IFile) => {
    setUploadedFileList((prevValues) =>
      prevValues.filter((uploadedFile) => uploadedFile !== file),
    );
    onFileRemove();
  };

  return {
    handleFileUpload,
    removeUploadedFile,
    fileErrors,
    uploadedFileList,
  };
};
