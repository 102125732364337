import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import { PropsWithChildren } from 'react';
import { FormProvider, useController, useForm } from 'react-hook-form';
import { useTranslation } from 'translations/hook';

import { useUploadFundDocument } from 'business/fund-manager/fund/services/hooks/use-upload-fund-document';
import { FundDocumentFormData } from 'business/fund-manager/operation/services/types';
import { fundDocumentSchema } from 'business/fund-manager/operation/services/validation';
import { UploadedFile } from 'business/shared/components/uploaded-file/uploaded-file';
import { FundDocumentType } from 'generated/graphql';
import { useFileUploadDeprecated } from 'technical/file-management/use-file-upload-deprecated';
import { ValidationErrors } from 'technical/validation/types';
import { ErrorLabel } from 'ui/error-label';
import { FileInput, FormInputDate, FormInputText, SelectInput } from 'ui/form';
import { FormModalContainer } from 'ui/form-modal-container';

interface Props extends PropsWithChildren {
  fundId: string;
  onClose: () => void;
  onCompleted: () => void;
  fundDocumentTypes: Pick<FundDocumentType, 'id' | 'name'>[];
}

export const FundDocumentModalForm = ({
  fundId,
  onClose,
  onCompleted,
  fundDocumentTypes,
}: Props) => {
  const { t } = useTranslation();

  const methods = useForm<FundDocumentFormData>({
    resolver: yupResolver<FundDocumentFormData>(fundDocumentSchema),
    defaultValues: {},
  });

  // TODO replace with useOnFilesUpload
  // Deprecated vs recommended:
  // https://gitlab.matters.tech/pe3/pe3/-/merge_requests/1451/diffs?commit_id=ce36a36454156b73b1de24dc869658ec144a2616#6670b88327d58384986d2f0b108572930d1d7bbc_224_216
  const { handleFileUpload, removeUploadedFile, fileErrors, uploadedFileList } =
    useFileUploadDeprecated({
      onFileRemove: () => methods.setValue('filePath', ''),
      onFileUpload: (file) => methods.setValue('filePath', file.filePath),
    });
  // const onFilesUpload = useOnFilesUpload({
  //   getFiles: () => methods.getValues('files') ?? [],
  // });

  const {
    fieldState: { error: fieldError },
  } = useController<FundDocumentFormData>({
    name: 'filePath',
    control: methods.control,
  });

  const { uploadDocument } = useUploadFundDocument({
    onCompleted,
  });

  const onSubmit = (data: FundDocumentFormData) =>
    uploadDocument({ ...data, fundId }).catch(() => undefined);

  return (
    <FormProvider {...methods}>
      <FormModalContainer
        title={t('pages.fundManager.lpDocument.form.title')}
        onClose={onClose}
        onSubmit={methods.handleSubmit(onSubmit)}
        isSubmitting={methods.formState.isSubmitting}
        labelAction={t('common.actions.upload')}
      >
        <Stack
          justifyContent="center"
          alignItems="center"
          spacing={4}
          padding={2}
        >
          <SelectInput
            name="fundDocumentTypeId"
            label={t('pages.document.table.type')}
            options={
              fundDocumentTypes.map(({ id, name }) => {
                return { id, value: name };
              }) ?? []
            }
            required
            fullWidth
          />
          <FormInputText
            name="documentName"
            label={t('pages.document.table.name')}
            required
            fullWidth
          />
          <FormInputDate
            name="emissionDate"
            label={t('pages.document.table.emissionDate')}
            fullWidth
          />
          <FormInputDate
            name="expirationDate"
            label={t('pages.document.table.expirationDate')}
            fullWidth
          />
          <FileInput onChange={handleFileUpload} />

          {uploadedFileList
            ? uploadedFileList.map((file) => (
                <UploadedFile
                  file={file}
                  handleRemoveFile={removeUploadedFile}
                  key={file.filePath}
                />
              ))
            : null}

          {fieldError ? (
            <ErrorLabel
              label={fieldError.message ?? ValidationErrors.GENERIC}
            />
          ) : null}
          {fileErrors ? <ErrorLabel label={fileErrors} /> : null}
        </Stack>
      </FormModalContainer>
    </FormProvider>
  );
};
