import { SelectionChangedEvent } from 'ag-grid-community';
import { Control, useController } from 'react-hook-form';
import { useTranslation } from 'translations/hook';

import { getSelectableLpColumnDefinition } from 'business/fund-manager/onboarding-subscription/services/get-selectable-lp-column-definition';
import { CreateOnboardingSubscription } from 'business/fund-manager/onboarding-subscription/services/types';
import { ActiveFundsAndLpsQuery } from 'generated/graphql';
import { AlternativeTable } from 'ui/alternative-table';

export const LpSelectableTable = ({
  lps,
  control,
}: {
  lps: Pick<
    ActiveFundsAndLpsQuery['activeFundsAndLps']['lps'][number],
    'subscribingEntityName'
  >[];
  control: Control<CreateOnboardingSubscription>;
}) => {
  const { t } = useTranslation();
  const columnDefs = getSelectableLpColumnDefinition(t);

  const { field } = useController<CreateOnboardingSubscription>({
    control,
    name: 'subscribingEntityNames',
  });

  const onSelectionChanged = (
    event: SelectionChangedEvent<
      Pick<
        ActiveFundsAndLpsQuery['activeFundsAndLps']['lps'][number],
        'subscribingEntityName'
      >
    >,
  ) => {
    const subscribingEntityNames = event.api
      .getSelectedRows()
      .map((elt) => elt.subscribingEntityName);
    field.onChange(subscribingEntityNames);
  };

  return (
    <AlternativeTable<
      Pick<
        ActiveFundsAndLpsQuery['activeFundsAndLps']['lps'][number],
        'subscribingEntityName'
      >
    >
      columnDefs={columnDefs}
      fileName="lps"
      rowData={lps}
      rowSelection="multiple"
      enableRowGroup={false}
      rowMultiSelectWithClick={true}
      isRowSelectable={(rowData) => !!rowData.data?.subscribingEntityName}
      onSelectionChanged={onSelectionChanged}
      // We need to set domLayout to autoHeight to avoid a bug for Safari users
      // It might be related to parent components
      domLayout="autoHeight"
    />
  );
};
