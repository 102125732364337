import UploadRoundedIcon from '@mui/icons-material/UploadRounded';
import { InputLabel, Stack } from '@mui/material';
import classNames from 'classnames';
import { ChangeEvent } from 'react';
import { useTranslation } from 'translations/hook';

import { MAX_ATTACHMENTS_FILE_SIZE_MB } from 'technical/file-management/constant';
import { QueryStateDisplay } from 'ui/query-state-display';
import { Typo } from 'ui/typo';
import { TypoColorEnum } from 'ui/typo/types';

import styles from './index.module.scss';

interface Props {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  fileExtensionAcceptList?: string[];
  disabled?: boolean;
  className?: string;
  name?: string;
  loading?: boolean;
  label?: string;
  hasError?: boolean;
  maximumFileSizeInMb?: number;
}

const defaultFileExtensionAcceptList = [
  '.jpeg',
  '.png',
  '.gif',
  '.doc',
  '.docx',
  '.xlsx',
  '.csv',
  '.pdf',
];

// TODO move this component outside ui/form dir
// this dir is only for input that handle react-hook-form registration
// TODO need to be deprecated because this not handle error at all
// please prefer to use form-file-input
// ex: available in CompanyMetricCreationForm
export function FileInput({
  onChange,
  fileExtensionAcceptList = defaultFileExtensionAcceptList,
  maximumFileSizeInMb = MAX_ATTACHMENTS_FILE_SIZE_MB,
  disabled = false,
  name = 'file',
  className,
  loading,
  label,
  hasError,
}: Props) {
  const { t } = useTranslation();

  if (loading) {
    return (
      <Stack
        className={classNames(styles.stack, className ?? '')}
        alignItems="center"
      >
        <QueryStateDisplay loading={loading} />
      </Stack>
    );
  }

  const {
    subtitleColor,
    mainColor,
  }: Record<'subtitleColor' | 'mainColor', TypoColorEnum> = hasError
    ? { subtitleColor: TypoColorEnum.error, mainColor: TypoColorEnum.error }
    : {
        subtitleColor: TypoColorEnum.neutralLight,
        mainColor: TypoColorEnum.neutral,
      };

  return (
    <Stack spacing={1}>
      {label ? <InputLabel>{label}</InputLabel> : null}
      <Stack
        className={classNames(styles.stack, className ?? '')}
        sx={{
          borderColor: hasError ? 'error.main' : 'primary.main',
        }}
      >
        <Typo color={mainColor} size="3xl">
          <UploadRoundedIcon fontSize="inherit" />
        </Typo>
        <Typo color={mainColor}>{t('ui.form.fileInput.title')}</Typo>
        <Typo size="xs" italic color={subtitleColor}>
          {t('ui.form.fileInput.maximumFileSize', {
            maximumFileSize: `${maximumFileSizeInMb} MB`,
          })}
        </Typo>
        <Typo size="xs" italic color={subtitleColor}>
          {t('ui.form.fileInput.fileExtensionAcceptList', {
            fileExtensionAcceptList: fileExtensionAcceptList.join(', '),
          })}
        </Typo>
        <input
          disabled={disabled}
          type="file"
          accept={fileExtensionAcceptList.join(',')}
          name={name}
          onChange={(e) => onChange(e)}
        />
      </Stack>
    </Stack>
  );
}
