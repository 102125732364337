import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'translations/hook';

import { UltimateEffectiveControlInput } from 'business/shared/lp-creation-module/components/ultimate-effective-control-input/ultimate-effective-control-input';
import { getLegalEntityContact } from 'business/shared/lp-creation-module/services/get-legal-entity-kyc-contact';
import {
  LegalEntityKYCContact,
  LpContactFormComponents,
} from 'business/shared/lp-creation-module/services/types';
import { legalEntityKYCContactSchema } from 'business/shared/lp-creation-module/services/validation';
import {
  ContactTypeEnum,
  Web_Portal_PublicationStatus_Enum,
  useCreateLegalEntityKycContactMutation,
  useUpdateLegalEntityKycContactMutation,
} from 'generated/graphql';
import { formatAsDate } from 'technical/date';
import { FormToggleButton } from 'ui/form';
import { SelectContactRoles } from 'ui/form/select-contact-roles';
import { ModalLpContactForm } from 'ui/modal-lp-contact-form';
import { ContactFormContextEnum } from 'ui/types';

export const LegalEntityKYCContactForm = ({
  lpId,
  context,
  contact: currentContact,
  onChanged: onCompleted,
  ...rest
}: LpContactFormComponents) => {
  const [createContactMutation, createResult] =
    useCreateLegalEntityKycContactMutation({
      onCompleted,
    });

  const [updateContactMutation, updateResult] =
    useUpdateLegalEntityKycContactMutation({
      onCompleted,
    });

  const onSubmit = (
    contactData:
      | LegalEntityKYCContact
      | (Omit<LegalEntityKYCContact, 'contact.'> & { contact: any }),
  ) => {
    const {
      type,
      contact,
      address,
      roles,
      ultimateEffectiveControlPercentage,
      taxResidenceCountry,
      taxIdentificationNumber,
      controlType,
      controlTypeOther,
    } = contactData;

    if (currentContact) {
      return updateContactMutation({
        variables: {
          input: {
            lpId,
            contactId: currentContact.id,
            roles,
            ultimateEffectiveControlPercentage,
            taxResidenceCountry,
            taxIdentificationNumber,
            controlType,
            controlTypeOther,
            contact: {
              ...contact,
              legalEntity:
                type === ContactTypeEnum.LegalEntity
                  ? contact.legalEntity
                  : undefined,
              birthDate: contact.birthDate
                ? formatAsDate(contact.birthDate)
                : null,
            },
            address,
          },
        },
        // error aren't handle by react-hook-form
      }).catch(() => undefined);
    }

    return createContactMutation({
      variables: {
        input: {
          lpId,
          type,
          contact: {
            ...contact,
            legalEntity:
              type === ContactTypeEnum.LegalEntity
                ? contact.legalEntity
                : undefined,
            birthDate: contact.birthDate
              ? formatAsDate(contact.birthDate)
              : null,
          },
          address,
          roles,
          ultimateEffectiveControlPercentage,
          taxResidenceCountry,
          taxIdentificationNumber,
          controlType,
          controlTypeOther,
        },
      },
      // error aren't handle by react-hook-form
    }).catch(() => undefined);
  };

  const methods = useForm<LegalEntityKYCContact>({
    resolver: yupResolver<LegalEntityKYCContact>(legalEntityKYCContactSchema),
    defaultValues: getLegalEntityContact(currentContact),
  });

  const { t } = useTranslation();

  return (
    <FormProvider {...methods}>
      <ModalLpContactForm
        {...rest}
        onSubmit={methods.handleSubmit(onSubmit)}
        isSubmitting={methods.formState.isSubmitting}
        context={
          currentContact?.publicationStatus ===
          Web_Portal_PublicationStatus_Enum.PendingValidation
            ? ContactFormContextEnum.VALIDATE
            : context
        }
        error={createResult?.error?.message || updateResult?.error?.message}
      >
        <SelectContactRoles />
        <UltimateEffectiveControlInput />
        <FormToggleButton
          name="type"
          disabled={context !== ContactFormContextEnum.ADD}
          label={t(
            'pages.fundManager.lpCreation.form.generalInformation.client.label',
          )}
          options={Object.values(ContactTypeEnum).map((value) => ({
            value,
            label: t(
              `pages.fundManager.lpCreation.form.generalInformation.client.${value}`,
            ),
          }))}
          required
        />
      </ModalLpContactForm>
    </FormProvider>
  );
};
