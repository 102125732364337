// eslint-disable-next-line import/no-extraneous-dependencies
import {
  IgnisignJs,
  IgnisignJS_SignatureSession_Initialization_Params,
} from '@ignisign/js';
import {
  IGNISIGN_APPLICATION_ENV,
  IGNISIGN_ERROR_CODES,
  IGNISIGN_SIGNATURE_LANGUAGES,
} from '@ignisign/public';
import { Stack } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'translations/hook';

import FundManagerRoutes from 'business/fund-manager/router/routes';
import LpPlatformRoutes from 'business/lp-platform/router/routes';
import { useLanguageContext } from 'business/providers/language-provider';
import { VariantTypeEnum } from 'business/providers/notifications/types';
import { useConfigClientVariable } from 'business/shared/services/hooks/use-config-client-variable';
import {
  ConfigClientVariableKeyEnum,
  GetSignatureSessionInfoQueryResult,
  useGetSignatureSessionInfoQuery,
  useSaveSignatureErrorMutation,
  useSignSignatureRequestMutation,
} from 'generated/graphql';
import { QueryStateDisplay } from 'ui/query-state-display';
type OnboardingSubscriptionSignatureIframeProps = {
  signatureSessionInfo: NonNullable<
    GetSignatureSessionInfoQueryResult['data']
  >['signatureSessionInfo'];
  onboardingSubscriptionId: string;
  redirectionLink: LpPlatformRoutes | FundManagerRoutes;
};

const SignatureSessionIframe = ({
  signatureSessionInfo,
  onboardingSubscriptionId,
  redirectionLink,
}: OnboardingSubscriptionSignatureIframeProps) => {
  const { locale: currentLanguage } = useLanguageContext();
  const ignisignHtmlElementId = 'ignisign-sdk-iframe';
  const navigate = useNavigate();
  const { t } = useTranslation();

  const ignisignAppId = useConfigClientVariable(
    ConfigClientVariableKeyEnum.IgnisignAppId,
  );
  const ignisignAppEnvironment = useConfigClientVariable(
    ConfigClientVariableKeyEnum.IgnisignEnvironment,
  );

  const [signSignatureRequestMutation] = useSignSignatureRequestMutation({
    onCompleted: () => {
      enqueueSnackbar(t('successMessage.signedDocument'), {
        variant: VariantTypeEnum.SUCCESS,
      });
      navigate(redirectionLink);
    },
    onError: () => {
      enqueueSnackbar(t('errors.signedDocument'), {
        variant: VariantTypeEnum.ERROR,
      });
    },
  });

  const [saveSignatureError] = useSaveSignatureErrorMutation({
    // two errors because we register the error from ignisign
    onCompleted: () => {
      enqueueSnackbar(t('errors.signedDocument'), {
        variant: VariantTypeEnum.ERROR,
      });
    },
    onError: () => {
      enqueueSnackbar(t('errors.signedDocument'), {
        variant: VariantTypeEnum.ERROR,
      });
    },
  });

  const handleSignatureSessionFinalized = async (
    signatureIds: string[],
    signerId: string,
    signatureRequestId: string,
  ) => {
    signSignatureRequestMutation({
      variables: {
        input: {
          signerId,
          signatureRequestId,
          onboardingSubscriptionId,
          signatureId: signatureIds?.[0] ?? '',
        },
      },
    });
  };

  const handleSignatureSessionError = async (
    errorCode: IGNISIGN_ERROR_CODES,
    _errorContext: any,
    signerId: string,
    signatureRequestId: string,
  ) => {
    saveSignatureError({
      variables: {
        input: {
          signerId,
          signatureRequestId,
          onboardingSubscriptionId,
          errorCode,
        },
      },
    });
  };

  const initIgnisignIframe = async () => {
    const ignisign = new IgnisignJs(
      ignisignAppId,
      // no need for typeguard here we let iframe handle bad value
      ignisignAppEnvironment as IGNISIGN_APPLICATION_ENV,
    );

    const params: IgnisignJS_SignatureSession_Initialization_Params = {
      htmlElementId: 'ignisign-sdk-iframe',
      signatureRequestId: signatureSessionInfo?.signatureRequestId,
      signerId: signatureSessionInfo.signerId,
      signatureSessionToken: signatureSessionInfo.signatureSessionToken,
      signerAuthSecret: signatureSessionInfo.signerAuthSecret,
      sessionCallbacks: {
        handleSignatureSessionError,
        handleSignatureSessionFinalized,
      },
      closeOnFinish: false,
      dimensions: {
        width: '100%',
        height: '710',
      },
      displayOptions: {
        forceLanguage:
          currentLanguage === 'fr'
            ? IGNISIGN_SIGNATURE_LANGUAGES.FR
            : IGNISIGN_SIGNATURE_LANGUAGES.EN,
      },
    };

    ignisign.initSignatureSession(params);
  };

  useEffect(() => {
    initIgnisignIframe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack>
      <div id={ignisignHtmlElementId}></div>
    </Stack>
  );
};

type SignatureSessionLoaderProps = {
  onboardingSubscriptionId: string;
  redirectionLink: LpPlatformRoutes | FundManagerRoutes;
};
export const SignatureIframe = ({
  onboardingSubscriptionId,
  redirectionLink,
}: SignatureSessionLoaderProps) => {
  const { loading, data, error } = useGetSignatureSessionInfoQuery({
    variables: {
      input: {
        onboardingSubscriptionId,
      },
    },
    fetchPolicy: 'network-only',
  });

  if (loading || error || !data?.signatureSessionInfo) {
    return <QueryStateDisplay loading={loading} error={error} />;
  }

  return (
    <SignatureSessionIframe
      onboardingSubscriptionId={onboardingSubscriptionId}
      signatureSessionInfo={data.signatureSessionInfo}
      redirectionLink={redirectionLink}
    />
  );
};
