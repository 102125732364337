import { Stack } from '@mui/material';

import { FileFormData } from 'business/fund-manager/shared/services/types';
import { UploadedFile } from 'business/shared/components/uploaded-file/uploaded-file';
import { useDocumentUpload } from 'business/shared/services/hooks/use-document-upload';
import { IFile } from 'technical/file-management/types';
import { ValidationErrors } from 'technical/validation/types';
import { ErrorLabel } from 'ui/error-label';
import { FileInput } from 'ui/form';
import { PreviewFile } from 'ui/preview-file';

type ExistingLogo = {
  url: string;
  name: string;
};

interface LogoUploadFormProps {
  onSubmit: (formData: FileFormData) => void;
  onExistingLogoDelete: () => void;
  existingLogo?: ExistingLogo;
}

type FileDisplayProps = {
  originalFile: File | null;
  uploadedFile: IFile;
  removeUploadedFile: (file: IFile) => void;
  existingLogo?: ExistingLogo;
  onExistingLogoDelete: () => void;
  handleFileInputOnChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const FileInterraction = ({
  originalFile,
  uploadedFile,
  removeUploadedFile,
  existingLogo,
  onExistingLogoDelete,
  handleFileInputOnChange,
}: FileDisplayProps) => {
  const shouldDisplayFileUpload = !existingLogo && originalFile && uploadedFile;

  if (shouldDisplayFileUpload) {
    return (
      <UploadedFile
        file={uploadedFile}
        handleRemoveFile={removeUploadedFile}
        key={uploadedFile.filePath}
        originalFile={originalFile}
        previewFile
      />
    );
  } else if (existingLogo) {
    return (
      <PreviewFile
        url={existingLogo.url}
        name={existingLogo.name}
        handleDelete={onExistingLogoDelete}
      />
    );
  } else {
    return (
      <FileInput
        onChange={handleFileInputOnChange}
        fileExtensionAcceptList={['.jpg', '.jpeg', '.png']}
      />
    );
  }
};

export const LogoUploadForm = ({
  onSubmit,
  onExistingLogoDelete,
  existingLogo,
}: LogoUploadFormProps) => {
  const onFileRemove = () => onSubmit({ filePath: '', uploadDate: new Date() });
  const onFileUpload = (file: IFile) =>
    onSubmit({ filePath: file.filePath, uploadDate: new Date() });

  const {
    removeUploadedFile,
    fileErrors,
    uploadedFileList,
    handleOnChange,
    uploadedFile,
    imgMaxDimensionsExceeded,
  } = useDocumentUpload({ onFileRemove, onFileUpload });

  return (
    <Stack justifyContent="center" spacing={5} padding={2}>
      <FileInterraction
        originalFile={uploadedFile}
        uploadedFile={uploadedFileList[0]}
        removeUploadedFile={removeUploadedFile}
        existingLogo={existingLogo}
        onExistingLogoDelete={onExistingLogoDelete}
        handleFileInputOnChange={handleOnChange}
      />
      {imgMaxDimensionsExceeded ? (
        <ErrorLabel label={ValidationErrors.MAX_IMG_DIMENSIONS_EXCEEDED} />
      ) : null}
      {fileErrors ? <ErrorLabel label={fileErrors} /> : null}
    </Stack>
  );
};
