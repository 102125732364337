import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import { PropsWithChildren } from 'react';
import { FormProvider, useController, useForm } from 'react-hook-form';
import { useTranslation } from 'translations/hook';

import { getLpDocumentTypeOptions } from 'business/fund-manager/lp/services/get-lp-document-type-options';
import { useUploadLpDocument } from 'business/fund-manager/lp/services/hooks/use-upload-lp-document';
import { UploadedFile } from 'business/shared/components/uploaded-file/uploaded-file';
import { LpDocument } from 'business/shared/services/document/types';
import { lpDocumentSchema } from 'business/shared/services/document/validation';
import { LpDocTypeWithDocumentQueryResult } from 'generated/graphql';
import { useFileUploadDeprecated } from 'technical/file-management/use-file-upload-deprecated';
import { ValidationErrors } from 'technical/validation/types';
import { ErrorLabel } from 'ui/error-label';
import { FileInput, FormInputDate, FormInputText, SelectInput } from 'ui/form';
import { FormModalContainer } from 'ui/form-modal-container';
import { QueryStateDisplay } from 'ui/query-state-display';

interface Props extends PropsWithChildren {
  lpId: string;
  onClose: () => void;
  onCompleted: () => void;
  preselectedTypeId?: string;
  lpDocumentTypes: LpDocTypeWithDocumentQueryResult['data'];
}

// TODO: better error handeling !!!!!!!!!!!!
export const LpDocumentModalForm = ({
  lpId,
  onClose,
  onCompleted,
  preselectedTypeId,
  lpDocumentTypes,
}: Props) => {
  const { t } = useTranslation();

  const methods = useForm<LpDocument>({
    resolver: yupResolver<LpDocument>(lpDocumentSchema),
    defaultValues: {
      type: preselectedTypeId,
    },
  });

  const { handleFileUpload, removeUploadedFile, fileErrors, uploadedFileList } =
    useFileUploadDeprecated({
      onFileRemove: () => methods.setValue('filePath', ''),
      onFileUpload: (file) => methods.setValue('filePath', file.filePath),
    });

  const {
    fieldState: { error: fieldError },
  } = useController<LpDocument>({
    name: 'filePath',
    control: methods.control,
  });

  const { uploadDocument, loading: lpDocumentLoading } = useUploadLpDocument({
    onCompleted,
  });

  const onSubmit = (data: LpDocument) =>
    uploadDocument({ ...data, lpId }).catch(() => undefined);

  if (lpDocumentLoading) {
    return <QueryStateDisplay loading={lpDocumentLoading} />;
  }

  return (
    <FormProvider {...methods}>
      <FormModalContainer
        title={t('pages.fundManager.lpDocument.form.title')}
        onClose={onClose}
        onSubmit={methods.handleSubmit(onSubmit)}
        isSubmitting={methods.formState.isSubmitting}
        labelAction={t('common.actions.upload')}
      >
        <>
          <Stack
            justifyContent="center"
            alignItems="center"
            spacing={4}
            padding={2}
          >
            <SelectInput
              name="type"
              label={t('pages.document.table.type')}
              options={getLpDocumentTypeOptions(t, lpDocumentTypes)}
              required
              fullWidth
            />
            <FormInputText
              name="fileName"
              label={t('pages.document.table.name')}
              required
              fullWidth
            />
            <FormInputDate
              name="emissionDate"
              label={t('pages.document.table.emissionDate')}
              fullWidth
            />
            <FormInputDate
              name="expirationDate"
              label={t('pages.document.table.expirationDate')}
              fullWidth
            />
            <FileInput onChange={handleFileUpload} />

            {uploadedFileList
              ? uploadedFileList.map((file) => (
                  <UploadedFile
                    file={file}
                    handleRemoveFile={removeUploadedFile}
                    key={file.filePath}
                  />
                ))
              : null}

            {fieldError ? (
              <ErrorLabel
                label={fieldError.message ?? ValidationErrors.GENERIC}
              />
            ) : null}
            {fileErrors ? <ErrorLabel label={fileErrors} /> : null}
          </Stack>
        </>
      </FormModalContainer>
    </FormProvider>
  );
};
