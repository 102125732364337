import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import { TFunction, useTranslation } from 'translations/hook';

import { useCompanyDocumentUpload } from 'business/fund-manager/portfolio/company/services/hooks/use-company-document-upload';
import { CompanyFileUploadForm } from 'business/fund-manager/portfolio/company/services/types';
import { companyFileUploadFormSchema } from 'business/fund-manager/portfolio/company/services/validation';
import { UploadedFile } from 'business/shared/components/uploaded-file/uploaded-file';
import { CompanyDocumentTypeEnum } from 'generated/graphql';
import { useFileUploadDeprecated } from 'technical/file-management/use-file-upload-deprecated';
import { ModalHeader } from 'ui/custom-modal/header';
import { ErrorLabel } from 'ui/error-label';
import { FileInput, FormInputDate, FormInputText, SelectInput } from 'ui/form';

export const getCompanyDocumentTypeOptions = (t: TFunction) => {
  return Object.values(CompanyDocumentTypeEnum)
    .filter((type) => type !== CompanyDocumentTypeEnum.Logo)
    .map((type) => ({
      value: t(
        `pages.fundManager.portfolio.company.documentation.documentType.${type}`,
      ),
      id: `${type}`,
    }));
};

interface CompanyDocumentUploadFormProps {
  companyId: string;
  onCompleted: () => void;
  onClose: () => void;
}

export const CompanyDocumentUploadForm = ({
  companyId,
  onCompleted,
  onClose,
}: CompanyDocumentUploadFormProps) => {
  const { t } = useTranslation();

  const { control, handleSubmit, setValue } = useForm<CompanyFileUploadForm>({
    resolver: yupResolver(companyFileUploadFormSchema),
    defaultValues: {
      uploadDate: new Date(),
    },
  });

  const { handleFileUpload, removeUploadedFile, fileErrors, uploadedFileList } =
    useFileUploadDeprecated({
      onFileRemove: () => setValue('filePath', ''),
      onFileUpload: (file) => setValue('filePath', file.filePath),
    });

  const { uploadDocument, loading } = useCompanyDocumentUpload({
    onCompleted,
  });

  return (
    <form
      onSubmit={handleSubmit((data) => uploadDocument({ ...data, companyId }))}
    >
      <ModalHeader
        title={t(
          'pages.fundManager.portfolio.company.documentation.uploadDocument.title',
        )}
        onClose={onClose}
        canSubmit
      />

      <Stack justifyContent="center" spacing={5} padding={2}>
        <Stack spacing={4} alignSelf="center">
          <SelectInput
            name="type"
            label={t('pages.document.table.type')}
            control={control}
            options={getCompanyDocumentTypeOptions(t)}
            required
            fullWidth
            disabled={loading}
          />
          <FormInputText
            name="fileName"
            label={t('pages.document.table.name')}
            control={control}
            required
            disabled={loading}
          />
          <FormInputDate
            name="uploadDate"
            label={t('pages.document.table.uploadDate')}
            required
            control={control}
            disabled={loading}
          />

          <FileInput onChange={handleFileUpload} />

          {uploadedFileList
            ? uploadedFileList.map((file) => (
                <UploadedFile
                  file={file}
                  handleRemoveFile={removeUploadedFile}
                  key={file.filePath}
                />
              ))
            : null}

          {fileErrors ? <ErrorLabel label={fileErrors} /> : null}
        </Stack>
      </Stack>
    </form>
  );
};
