import { Stack } from '@mui/material';

import { UploadedFile } from 'business/shared/components/uploaded-file/uploaded-file';
import { useDocumentUpload } from 'business/shared/services/hooks/use-document-upload';
import { IFile } from 'technical/file-management/types';
import { ValidationErrors } from 'technical/validation/types';
import { ErrorLabel } from 'ui/error-label';
import { FileInput } from 'ui/form';

interface Props {
  onFileRemove: () => void;
  onFileUpload: (file: IFile) => void;
}

export const ImageUpload = ({ onFileRemove, onFileUpload }: Props) => {
  const {
    removeUploadedFile,
    fileErrors,
    uploadedFileList,
    handleOnChange,
    uploadedFile,
    imgMaxDimensionsExceeded,
  } = useDocumentUpload({ onFileRemove, onFileUpload });

  return (
    <Stack justifyContent="center" spacing={5} padding={2}>
      {uploadedFile && uploadedFileList[0] ? (
        <UploadedFile
          file={uploadedFileList[0]}
          handleRemoveFile={removeUploadedFile}
          key={uploadedFileList[0].filePath}
          originalFile={uploadedFile}
          previewFile
        />
      ) : (
        <FileInput
          onChange={handleOnChange}
          fileExtensionAcceptList={['.jpg', '.jpeg', '.png']}
        />
      )}
      {imgMaxDimensionsExceeded ? (
        <ErrorLabel label={ValidationErrors.MAX_IMG_DIMENSIONS_EXCEEDED} />
      ) : null}
      {fileErrors ? <ErrorLabel label={fileErrors} /> : null}
    </Stack>
  );
};
